import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { Colors } from 'constants/Colors';

const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 232px);
  background: ${Colors.white};
  padding-top: 81px;
  padding-bottom: 60px;
  display: flex;
  justify-content: center;
`;
const ContainWrapper = styled.div`
  max-width: 600px;
  width: 100%;
  height: 100%;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 30px;
`;
const ShopItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  @media only screen and (max-width: 768px) {
    justify-content: flex-end;
    flex-wrap: wrap;
  }
`;
const ColumnBetween = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  gap: 6px;
`;
const RowStart = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
`;
const RowStartProperty = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 4px;
  }
`;
const SizeButton = styled.div<{ selected?: boolean }>`
  min-width: 40px;
  height: 34px;
  padding: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Colors.black};
  background: ${({ selected }) => (selected ? Colors.black : Colors.white)};
  color: ${({ selected }) => (selected ? Colors.white : Colors.black)};
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
const ColorButton = styled.div<{ selected?: boolean; bgColor?: string }>`
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ bgColor }) => bgColor};
  border: 1px solid ${({ selected }) => (selected ? Colors.black : 'transparent')};
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;
const Text = styled.p<{ size: number; color: string; bold: string; isHover?: boolean }>`
  font-weight: ${({ bold }) => bold};
  font-size: ${({ size }) => `${size}px`};
  color: ${({ color }) => color};
  cursor: ${({ isHover }) => (isHover ? 'pointer' : '')};
  &:hover {
    opacity: ${({ isHover }) => (isHover ? '0.8' : '1')};
  }

  @media only screen and (max-width: 768px) {
    font-size: ${({ size }) => `${size > 60 ? size * 0.4 : size < 20 ? size : size * 0.7}px`};
  }

  @media only screen and (min-width: 768px) {
    font-size: ${({ size }) => `${size > 60 ? size * 0.5 : size < 20 ? size : size * 0.8}px`};
  }

  @media only screen and (min-width: 992px) {
    font-size: ${({ size }) => `${size > 60 ? size * 0.7 : size < 20 ? size : size * 0.9}px`};
  }

  @media only screen and (min-width: 1200px) {
    font-size: ${({ size }) => `${size > 60 ? size * 0.9 : size < 20 ? size : size}px`};
  }

  @media only screen and (min-width: 1400px) {
    font-size: ${({ size }) => `${size}px`};
  }
`;

const Transaction = () => {
  const { id } = useParams();
  const [transactionData, setTransactionData] = useState([]);


  useEffect(() => {
    try {
      axios.get(`${process.env.REACT_APP_BACKEND}/product?transaction_id=${id}`).then(response => {
        const data = [response?.data?.data];

        axios.get(`https://maalyportal.com/api/omerch/check-online-transaction-merch/${id}`).then(txRes => {
          const txData = txRes?.data;

          const realData = data.map(_oneData => {
            return {
              ..._oneData,
              products: JSON.parse(_oneData.products),
              isPaid: txData?.status && parseFloat(`${_oneData.total_price}`) === parseFloat(`${txData.fiatAmount}`),
            };
          });

          setTransactionData(realData);
        });
      });
    } catch (err) {
      console.log('Getting Data Error', err);
    }
  }, [id]);



  return (
    <Container>
      <ContainWrapper>
        {transactionData.length !== 0 &&
          transactionData.map((_tItem, _tIndex) => (
            <div key={_tIndex} style={{ borderBottom: '1px solid gray', marginBottom: '24px', paddingBottom: '12px' }}>
              <RowStart style={{ marginBottom: '12px' }}>
                <Text size={14} color={Colors.black} bold="400">
                  {_tItem?.transaction_id}
                </Text>
              </RowStart>
              <RowStart style={{ marginBottom: '12px' }}>
                <Text size={14} color={Colors.black} bold="400">
                  {_tItem?.name}
                </Text>
                <Text size={14} color={Colors.black} bold="400">
                  {_tItem?.email}
                </Text>
                <Text size={14} color={Colors.black} bold="400">
                  {_tItem?.phone_number}
                </Text>
                <Text size={14} color={Colors.black} bold="400">
                  {_tItem?.address}
                </Text>
                {_tItem?.isPaid && (
                  <Text
                    size={14}
                    color={Colors.white}
                    bold="600"
                    style={{ padding: '6px', backgroundColor: Colors.purple }}
                  >
                    PAID
                  </Text>
                )}
              </RowStart>
              {_tItem?.products?.map((item, index) => (
                <ShopItemWrapper key={index}>
                  <RowStart>
                    <img src={item?.src} alt="product" style={{ cursor: 'pointer' }} width={70} height={70} />
                    <ColumnBetween style={{ flex: 1 }}>
                      <Text size={18} color={Colors.black} bold="600">
                        {item?.name}
                      </Text>
                      <Text size={16} color={Colors.black} bold="500">
                        {item?.description}
                      </Text>
                      <RowStart>
                        <RowStartProperty>
                          <Text size={16} color={Colors.black} bold="400">
                            Size :
                          </Text>
                          <SizeButton selected={true}>{item?.size}</SizeButton>
                        </RowStartProperty>
                        <RowStartProperty>
                          <Text size={16} color={Colors.black} bold="400">
                            Color :
                          </Text>
                          <ColorButton selected={true} bgColor={item?.color}></ColorButton>
                        </RowStartProperty>
                      </RowStart>
                    </ColumnBetween>
                  </RowStart>
                  <RowStart>
                    <Text size={18} color={Colors.black} bold="600" style={{ minWidth: '72px' }}>
                      $ {item?.price} ( {item?.quantity} )
                    </Text>
                  </RowStart>
                </ShopItemWrapper>
              ))}
              <Text size={18} color={Colors.black} bold="600" style={{ textAlign: 'right' }}>
                Total Price : {`$ ${_tItem?.total_price}`}
              </Text>
            </div>
          ))}
      </ContainWrapper>
    </Container>
  );
};

export default Transaction;
